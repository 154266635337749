@import '../../../../scss/theme-bootstrap';

.gnav-links {
  &__container {
    background: $color-white;
    color: $color-black;
    @include breakpoint($large-up) {
      width: 230px;
      float: $ldirection;
      display: inline-block;
      text-align: $ldirection;
      margin-#{$rdirection}: 30px;
      background: $color-black;
      color: $color-white;
      &:first-child {
        margin-#{$ldirection}: 45px;
      }
    }
  }
  &__wrapper {
    padding: 13px 0;
    @include breakpoint($large-up) {
      padding: 0;
    }
  }
  &__url {
    margin-#{$ldirection}: 0;
    padding-#{$ldirection}: 20px;
    color: $color-black;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    position: relative;
    width: 100%;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 20px;
      padding-#{$ldirection}: 0;
      width: auto;
    }
    &:hover {
      text-decoration: none;
    }
    @include breakpoint($large-up) {
      color: $color-white;
      margin: 0;
      font-size: 14px;
    }
    &:before {
      position: absolute;
      top: 50%;
      #{$rdirection}: 0;
      transform: translateY(-50%);
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background: url(/media/images/global/icons/plus_black.svg) no-repeat center transparent;
      .gnav-links__container.active & {
        background: url(/media/images/global/icons/minus_black.svg) no-repeat center transparent;
      }
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
  &__title {
    color: $color-black;
    @include breakpoint($large-up) {
      color: $color-white;
      font-family: $header-font-bold;
    }
    a {
      color: $color-black;
      &:hover {
        color: $color-white;
      }
      @include breakpoint($large-up) {
        color: $color-white;
      }
    }
  }
  &__dropdown {
    margin-#{$ldirection}: 40px;
    @include breakpoint($large-up) {
      margin-#{$ldirection}: 0;
    }
    &--no-title {
      margin-#{$ldirection}: 0;
    }
    .gnav-link--subcategory-link {
      color: $color-black;
      @include breakpoint($large-up) {
        color: $color-white;
      }
      font-family: $main-font;
      font-size: 12px;
      text-transform: capitalize;
      line-height: 18px;
      padding: 10px 0;
      text-align: $ldirection;
      &:first-child {
        @include breakpoint($large-up) {
          padding-top: 20px;
        }
      }
      a {
        text-transform: capitalize;
        font-family: $main-font;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        color: $color-black;
        text-decoration: none;
        &:hover {
          @include breakpoint($large-up) {
            color: $color-white;
          }
        }
        @include breakpoint($large-up) {
          color: $color-white;
          font-size: 14px;
          &:hover {
            color: $cr19-text-dark-gray;
          }
        }
      }
    }
    .gnav-links__container:not(.active) & {
      display: none;
      @include breakpoint($large-up) {
        display: block;
      }
    }
  }
}
